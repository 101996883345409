<template>
    <NuxtLayout name="default">
        <GameMenu name="Adopt Me" image="/images/games/icons/adopt-me.png" :links="links" />
        <NuxtLayout name="ads">
            <slot />
        </NuxtLayout>
    </NuxtLayout>
</template>

<script setup lang="ts">
const links = [
    { title: 'Items', to: '/adopt-me/items' },
    { title: 'Trade Calculator', to: '/adopt-me/calculate' },
    { title: 'Trade Ads', to: '/adopt-me/ads' },
    { title: 'Value Changes', to: '/adopt-me/value-changes' }
]

useHead({
    titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - Adopt Me | RoValue` : 'Adopt Me - RoValue'
    }
})
</script>
